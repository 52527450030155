import generateBlockLink from '@/services/ecommerce/utils/LinkGenerator';
import { checkIsValidColor, hexToRgbaWithOpacity } from '@/services/ecommerce/utils/helper';
import cls from 'classnames';
import { map } from 'lodash';
import Slider from '../molecules/Slider';
import ProductCardBeauty from '../organisms/ProductCardBeauty';
import ProductCard from '../organisms/Products';
import ProductCardHorizontal from '../organisms/Products/Horizontal';
import BlockSeeAll from './SeeAll';
interface Props {
	data?: any;
	blockLinks?: any;
	sl?: any;
}

const CategoryBlock = ({ sl, data }) => {
	const { block_display_type } = data;
	const blockLinks = generateBlockLink(data);
	if (block_display_type === 'category_vertical_sidescroll') {
		return <CategorySideScrollVertical data={data} blockLinks={blockLinks} sl={sl} />;
	}
	return <div> </div>;
};

const CategorySideScrollVertical = ({ data, blockLinks, sl }: Props) => {
	const { block, block_data, block_weight, block_color_code, block_machine_name, block_display_type } = data;
	const products = block_data?.data || [];
	const { link } = blockLinks;
	if (!products.length) {
		return null;
	}
	const renderSlides = (block_display_type, newBlockData, i) => {
		switch (block_display_type) {
			case 'sidescroll_vertical':
				return <ProductCard data={newBlockData} key={i} />;
			case 'sidescroll_horizontal':
				return <ProductCardHorizontal data={newBlockData} key={i} />;

			case 'except_add_to_cart_button':
				return <ProductCardBeauty data={newBlockData} key={i} />;
			default:
				return <ProductCard data={newBlockData} key={i} />;
		}
	};

	const SlideComponent = (block_display_type) => {
		switch (block_display_type) {
			case 'sidescroll_vertical':
				return ProductCard;
			case 'sidescroll_horizontal':
				return ProductCardHorizontal;
			case 'except_add_to_cart_button':
				return ProductCardBeauty;
			default:
				return ProductCard;
		}
	};
	return (
		<div
			className={cls('py-30 ')}
			style={{
				position: 'relative',
				zIndex: 100 - parseInt(sl),
				backgroundColor: checkIsValidColor(block_color_code)
					? `${hexToRgbaWithOpacity(block_color_code, 0.1)}`
					: 'transparent'
			}}>
			<div className='container product-slider'>
				<div className='d-flex justify-space-between items-center mb-30'>
					<div className='text-30 text-grey900 fw-600'>
						<h2
							className='text-30 fw-600 mb-0'
							style={{
								color: block_color_code
							}}>
							{block}
						</h2>
					</div>

					<BlockSeeAll title='see all' link={link} color={block_color_code} data={data} />
				</div>

				<div className='my-10  mt-10 items-center'>
					<Slider
						slidesPerView={6}
						spaceBetween={20}
						shouldFetchData={block_machine_name}
						shouldSlidesPerGroup={true}
						SlideComponent={SlideComponent(block_display_type)}
						responsive={{
							'0': {
								slidesPerView: 2
							},
							'640': {
								slidesPerView: 2
							},
							'768': {
								slidesPerView: 3
							},
							'1024': {
								slidesPerView: 3,
								spaceBetween: 10
							},
							'1280': {
								slidesPerView: 4,
								spaceBetween: 10
							},
							'1440': {
								slidesPerView: 6
							}
						}}>
						{map(products, (d, i) => renderSlides(block_display_type, d, i))}
					</Slider>
				</div>
			</div>
		</div>
	);
};

export default CategoryBlock;
