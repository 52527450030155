'use client';

// import BlockContents from '@/components/organisms/BlockContents';
import { BlockContent } from '@/components/organisms/BlockContents';
import { useAlert } from '@/contexts/AlertProvider';
import AroggaAPI from '@/services/ecommerce/apis/AroggaAPI';
import { API_PUBLIC } from '@/services/ecommerce/apis/apiConstrant';
import useDeepCompareMemo from '@/services/ecommerce/hooks/useDeepCompareMemo';
import { get, sortBy } from 'lodash';
import { useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { ThreeDots } from 'react-loader-spinner';

export const MoreBlockContents = () => {
	const [page, setPage] = useState(2);
	const alert = useAlert();
	const [data, setData] = useState({
		region_block_data: {
			blocks: []
		}
	});
	const [hasMore, setHasMore] = useState(true);
	const memoizedFilter = useDeepCompareMemo({
		_page: page,
		_region: 'web_medicine_home',
		_perPage: 10,
		_allow_sales: 1,
		_is_base: 1
	});

	const fetchBlocks = async () => {
		const { data: blockData, status, message } = await AroggaAPI.get(API_PUBLIC.GET_BLOCK, memoizedFilter);
		if (message === 'Maintenance, Please try again later') {
			alert.error({
				message: 'Maintenance, Please try again later',
				title: 'Error'
				// onConfirm: () => {
				// 	window.location.href = '/maintenance';
				// },
			});
		}
		if (status === 'fail') {
			setHasMore(false);
			return;
		}

		setData((prevData) => ({
			region_block_data: {
				blocks: [...prevData.region_block_data?.blocks, ...(blockData?.region_block_data?.blocks || [])]
			}
		}));
		if (blockData?.region_block_data?.blocks?.length < 10) {
			setHasMore(false);
			return;
		}

		setPage((prevPage) => prevPage + 1);
	};

	const handleNextPage = () => {
		if (hasMore) {
			fetchBlocks();
		}
	};

	return (
		<InfiniteScroll
			dataLength={data.region_block_data.blocks.length}
			hasMore={hasMore}
			next={handleNextPage}
			style={{ overflow: 'hidden' }}
			scrollThreshold={0.1}
			loader={
				<div className='d-flex justify-center items-center mb-20'>
					<ThreeDots
						visible={true}
						height='80'
						width='80'
						color='#0E7673'
						radius='9'
						ariaLabel='three-dots-loading'
						wrapperStyle={{}}
						wrapperClass=''
					/>
				</div>
			}>
			{sortBy(get(data, 'region_block_data.blocks', []), 'block_weight').map((data: any, i: number) => (
				<BlockContent key={i} start={11 + i} data={data} />
			))}
		</InfiniteScroll>
	);
};
