import { IM_APP_STORE, IM_GOOGLE_PLAY } from '@/assets/images';
import { getImage } from '@/services/ecommerce/utils/helper';
import Image from 'next/image';
import Link from 'next/link';
function convertToJSXArray(inputStr) {
	const parts = inputStr.split('|').flatMap((part, idx) => {
		if (idx !== 0) {
			return ['\n', ...part.split('#')];
		}
		return part.split('#');
	});

	// Iterate through the split string and wrap every second element with the desired tag
	const jsxParts = [];
	for (let i = 0; i < parts.length; i++) {
		if (parts[i] === '\n') {
			jsxParts.push('LineBreak');
		} else if (i % 2 === 1) {
			jsxParts.push('<span class="text-error">' + parts[i] + '</span>');
		} else {
			jsxParts.push(parts[i]);
		}
	}

	return jsxParts;
}
const DownloadSection = ({ data }) => {
	return (
		<div className='bg-primary_bg pt-0 mb-0'>
			<div className='container row pb-0'>
				<div
					className='col-12 col-md-6'
					style={{
						display: 'flex',
						flexDirection: 'column',
						justifyContent: 'center'
					}}>
					<div className='text-grey900 text-white fw-700 text-40 mb-15'>{data.block}</div>
					<div className='text-grey900 text-white fw-700 text-40 mb-10'>{data.block_subtitle}</div>
					<div className='text-grey600 text-white mb-20' style={{ maxWidth: '430px' }}>
						{convertToJSXArray(data.block_data.title).map((part, idx) => {
							if (part === 'LineBreak') {
								return <br key={idx} />;
							} else {
								return (
									<span
										key={idx}
										dangerouslySetInnerHTML={{
											__html: part || '<div></div>'
										}}
									/>
								);
							}
						})}
					</div>
					<div className='text-18 text-grey900 fw-500 mb-15'>Download our app</div>
					<div className='d-flex gap-10'>
						<Link
							href={'https://play.google.com/store/apps/details?id=com.arogga.app&hl=en_US'}
							target='_blank'
							rel='noopener noreferrer'>
							<Image src={IM_GOOGLE_PLAY} alt='how to order video' unoptimized />
						</Link>
						<Link
							href={'https://apps.apple.com/us/app/arogga/id1499378347'}
							target='_blank'
							rel='noopener noreferrer'>
							<Image src={IM_APP_STORE} alt='how to order video' unoptimized />
						</Link>
					</div>
				</div>
				<div
					className='col-12 col-md-6'
					style={{
						position: 'relative',
						padding: 0,
						margin: 0
					}}>
					<Image
						unoptimized={true}
						height={600}
						width={600}
						src={getImage(data.block_data.file)}
						alt='how to order video'
						style={{
							height: 600,
							width: '100%',
							objectFit: 'contain'
						}}
					/>
				</div>
			</div>
		</div>
	);
};

export default DownloadSection;
