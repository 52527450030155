import Slider from '@/components/molecules/Slider';
import { getImage, hexToRgbaWithOpacity } from '@/services/ecommerce/utils/helper';
import { ActionBlockData } from '@/services/types/blocksTypes';
import cls from 'classnames';
import { map } from 'lodash';
import Image from 'next/image';
import Link from 'next/link';
import React from 'react';
import styles from './styles.module.scss';

interface ActionsProps {
	data: ActionBlockData[];
	label: string;
	blockLinks?: any;
}

const SidescrollUpToMoneyRange: React.FC<ActionsProps> = ({ data, label, blockLinks }) => {
	const { children } = blockLinks;
	return (
		<div className={cls('py-30 ', styles.sidescroll_up_to_money_range)}>
			<div className='container product-slider'>
				<Slider
					slidesPerView={4.6}
					spaceBetween={20}
					responsive={{
						'0': {
							slidesPerView: 2
						},
						'640': {
							slidesPerView: 2
						},
						'768': {
							slidesPerView: 3,
							spaceBetween: 20
						},
						'800': {
							slidesPerView: 3,
							spaceBetween: 20
						},
						'1024': {
							slidesPerView: 3,
							spaceBetween: 20
						},
						'1280': {
							slidesPerView: 4,
							spaceBetween: 20
						},
						'1400': {
							slidesPerView: 4.6,
							spaceBetween: 20
						},
						'1600': {
							slidesPerView: 4.6,
							spaceBetween: 20
						}
					}}>
					{map(data, (d: ActionBlockData, i: number) => {
						return (
							<Link href={children[i]?.link || '/'} key={i}>
								<div
									className={styles.card}
									style={{
										backgroundColor: hexToRgbaWithOpacity(d?.color_code, 0.4)
									}}>
									<div style={{ borderRadius: 5 }}>
										<div className={styles.card__image}>
											<Image alt='' src={getImage(d.file)} width={264} height={264} priority />
										</div>
									</div>
									<div
										className={styles.card__content}
										style={{
											backgroundColor: d?.color_code
										}}>
										<div className='text-white fw-400'>{d?.title}</div>
										<div className='text-white text-32 fw-700 mb-5'>{d.subtitle}</div>
									</div>
								</div>
							</Link>
						);
					})}
				</Slider>
			</div>
		</div>
	);
};

export default SidescrollUpToMoneyRange;
