import { getImage } from '@/services/ecommerce/utils/helper';
import { ActionBlockData } from '@/services/types/blocksTypes';
import Image from 'next/image';
import Link from 'next/link';
import React from 'react';

interface ActionsProps {
	data: ActionBlockData[];
	label: string;
	blockLinks?: any;
}

const Action2ColumnHorizontal: React.FC<ActionsProps> = ({ data, label, blockLinks }) => {
	const { link, children } = blockLinks;
	return (
		<div className='container mb-30'>
			<div style={{ marginBottom: 30 }}>
				<h2 className='text-gray900 text-30 fw-600'>{label}</h2>
			</div>

			<div
				style={{
					display: 'grid',
					gridTemplateColumns: 'repeat(3, 1fr)',
					gridGap: 20
				}}>
				{data?.map((d: ActionBlockData, i: number) => {
					return (
						<Link href={children[i].link} key={i}>
							<Image
								key={i}
								className='cursor-pointer'
								alt=''
								src={getImage(d.file)}
								width={d?.width || 80}
								height={d?.height || 80}
								style={{
									height: '100%',
									width: '100%',
									borderRadius: 6
								}}
								priority
							/>
						</Link>
					);
				})}
			</div>
		</div>
	);
};

export default Action2ColumnHorizontal;
