import Slider from '@/components/molecules/Slider';
import { getImage } from '@/services/ecommerce/utils/helper';
import { ActionBlockData } from '@/services/types/blocksTypes';
import cls from 'classnames';
import Image from 'next/image';
import Link from 'next/link';
import React from 'react';
import styles from './styles.module.scss';

interface ActionsProps {
	data: ActionBlockData[];
	label: string;
	blockLinks?: any;
}

const ActionsCategorySideScroll: React.FC<ActionsProps> = ({ data, label, blockLinks }) => {
	const { children } = blockLinks;
	return (
		<div className={cls('pb-30 ', styles.actions_category_side_scroll)}>
			<div className='container product-slider'>
				<div
					className='text-center '
					style={{
						textAlign: 'center',
						marginBottom: 30
					}}>
					<h2 className='text-gray900 text-30 fw-600'>{label}</h2>
				</div>
				<Slider slidesPerView={6.5} spaceBetween={20} itemClassName={styles.card} shouldSlidesPerGroup={true}>
					{data?.map((d: ActionBlockData, i: number) => {
						return (
							<Link href={children[i]?.link} key={i}>
								<div
									className={styles.card__image}
									style={{
										backgroundColor: `${d?.color_code}`
									}}>
									<Image alt='' src={getImage(d.file)} width={110} height={110} priority unoptimized />
								</div>
								<div className={styles.card__content}>
									<div className={styles.card__content__title}>{d?.title}</div>
								</div>
							</Link>
						);
					})}
				</Slider>
			</div>
		</div>
	);
};

export default ActionsCategorySideScroll;
