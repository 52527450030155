import { getImage } from '@/services/ecommerce/utils/helper';
import { ActionBlockData } from '@/services/types/blocksTypes';
import cls from 'classnames';
import { map } from 'lodash';
import Image from 'next/image';
import Link from 'next/link';
import styles from './styles.module.scss';

interface ActionsProps {
	data: ActionBlockData[];
	label: string;
	blockLinks?: any;
}

const ShopByConcern = ({ data, label, blockLinks }: ActionsProps) => {
	const { children } = blockLinks;
	return (
		<div className={cls('py-30 ', styles.shop_by_concern)}>
			<div className='container product-slider'>
				<div
					className='text-center '
					style={{
						textAlign: 'center',
						marginBottom: 30
					}}>
					<h2 className='text-gray900 text-30 fw-600'>{label}</h2>
				</div>
				<div className={styles.card}>
					{map(data, (d: any, i: number) => {
						return (
							<div className={styles.cardbody} key={i}>
								<div style={{ borderRadius: 5 }}>
									<div className={styles.card__image}>
										<Link href={children[i]?.link || '/'}>
											<Image alt='' src={getImage(d.file)} width={500} height={500} priority unoptimized />
										</Link>
									</div>
								</div>
								<div className={styles.category_card__content}>
									<Link href={children[i]?.link || '/'}>
										<div className='text-grey900 text-18 fw-700'>{d?.title}</div>
									</Link>
									<Link href={children[i]?.link || '/'}>
										<div className='text-grey500 text-16 fw-400'>{d?.subtitle}</div>
									</Link>
								</div>
							</div>
						);
					})}
				</div>
			</div>
		</div>
	);
};

export default ShopByConcern;
