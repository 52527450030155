import { getImage } from '@/services/ecommerce/utils/helper';
import Image from 'next/image';
import Link from 'next/link';
import React from 'react';

interface Props {
	data: any;
	label: string;
	blockLinks: any;
}
const ImageVertical2Column: React.FC<Props> = ({ data, label, blockLinks }) => {
	const { children } = blockLinks;
	return (
		<div className='container pb-30'>
			<div
				className='text-center '
				style={{
					textAlign: 'center',
					marginBottom: 30
				}}>
				<h2 className='text-gray900 text-30 fw-600'>{label}</h2>
			</div>
			<div className='grid grid-cols-2 gap-20'>
				{data?.map((d: any, i: number) => {
					return (
						<Link href={children[i]?.link || ''} key={i}>
							<Image
								className='cursor-pointer'
								alt=''
								src={getImage(d?.file)}
								width={d.width}
								height={d.height}
								style={{
									height: 'auto', //"100%",
									width: '100%',
									borderRadius: 6
								}}
								unoptimized
							/>
						</Link>
					);
				})}
			</div>
		</div>
	);
};

export default ImageVertical2Column;
