import { getImage } from '@/services/ecommerce/utils/helper';
import Image from 'next/image';

const HomeBannerBody = ({ data, blockLinks }) => {
	return (
		<div
			style={{
				position: 'relative'
			}}>
			<Image
				src={getImage(data.file)}
				alt='Description'
				unoptimized={true}
				height={parseInt(data.height)}
				width={parseInt(data.width)}
				loading='lazy'
				style={{
					width: '100%',
					height: 'auto'
				}}
			/>
			<div
				className='overlay-content'
				style={{
					position: 'absolute',
					top: '50%',
					left: '50%',
					transform: 'translate(-50%, -50%)',
					textAlign: 'center',
					color: 'white'
				}}></div>
		</div>
	);
};

export default HomeBannerBody;
