import Slider from '@/components/molecules/Slider';
import { getImage } from '@/services/ecommerce/utils/helper';
import { ActionBlockData } from '@/services/types/blocksTypes';
import cls from 'classnames';
import Image from 'next/image';
import Link from 'next/link';
import React from 'react';
import styles from './styles.module.scss';

interface ActionsProps {
	data: ActionBlockData[];
	label: string;
	blockLinks?: any;
}

const TopDeals: React.FC<ActionsProps> = ({ data, label, blockLinks }) => {
	const { children } = blockLinks;
	return (
		<div className={cls('pb-30 ', styles.top_deals)}>
			<div className='container product-slider'>
				<div
					className='text-center '
					style={{
						textAlign: 'center',
						marginBottom: 30
					}}>
					<h2 className='text-gray900 text-30 fw-600'>{label}</h2>
				</div>
				<Slider
					slidesPerView={4.6}
					spaceBetween={20}
					shouldSlidesPerGroup={true}
					responsive={{
						'0': {
							slidesPerView: 2
						},
						'640': {
							slidesPerView: 2
						},
						'768': {
							slidesPerView: 2
						},
						'800': {
							slidesPerView: 3.5,
							spaceBetween: 20
						},
						'1024': {
							slidesPerView: 3.7,
							spaceBetween: 20
						},
						'1280': {
							slidesPerView: 3.8,
							spaceBetween: 20
						},
						'1400': {
							slidesPerView: 4.6,
							spaceBetween: 20
						},
						'1600': {
							slidesPerView: 4.6,
							spaceBetween: 20
						}
					}}>
					{data?.map((d: ActionBlockData, i: number) => {
						return (
							<div className={styles.card} key={i}>
								<Link href={children[i]?.link || ''}>
									<div style={{ borderRadius: 5 }}>
										<div className={styles.card__image}>
											<Image alt='' src={getImage(d.file)} width={264} height={264} priority unoptimized />
										</div>
									</div>
									<div className={styles.category_card__content}>
										<div className='text-grey900 fw-600 mb-10'>{d?.title}</div>
										<div className='text-error fw-700 mb-5'>{d.subtitle}</div>
									</div>
								</Link>
							</div>
						);
					})}
				</Slider>
			</div>
		</div>
	);
};

export default TopDeals;
